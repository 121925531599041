<template>
 <div>
  <h4>реклама</h4>
   <div size="sm">
      <textarea rows="10" cols="70" v-model="row" ></textarea> 
       
   </div>
   <b-button variant="outline-primary" v-on:click="changeValueReachText(row)">Отправить</b-button>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
  
  data(){
      return {
         row: null,
		 
         //доступы
         role: '',
         email: '', 
         token: '',		 
      }
  },
 
  methods: {
     changeValueReachText(row) {   
        let data = encodeURIComponent(JSON.stringify({'q' : 'set_reclama', 'resp' : {'reclama' : this.row}, 'user' : {'email' : this.email, 'token' : this.token}})) 
         axios.post(this.$hostname, "data="+data)
         .then(response => {           
            alert(response.data)           
         })
         .catch(err => {
            alert('ошибка записи данных')
         })
     },
  },
  
  created() {
     /* доступы */
     if(localStorage.getItem('role') != null){
        this.role = localStorage.getItem('role')
     }
     
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
     }
     
     /* end */ 

     const data = JSON.stringify({'q' : 'get_reclama', 'user' : {'email' : this.email, 'token' : this.token}})  
     axios.post(this.$hostname, "data="+data)
        .then(response => {
		
            this.row = response.data.reclama 
		   
        })
        .catch(err => {
           console.log(err)
        })	 	 
        
  }
}  

</script>

<style>

</style>