<template>
  <div id="app">    
    <span v-if="isLoggedIn">
         <navbar></navbar>
         <a @click="logout" id="a_exit">выйти</a>           
    </span>
    <router-view/>
  </div>
</template>



<script>

import Navbar from './views/Navbar.vue'

  export default {
    components: {
      Navbar,
    },
    computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn}
    },
    methods: {
      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login')
        })
      }
    },
    created: function () {
      this.$http.interceptors.response.use(undefined, function (err) {
        return new Promise((resolve, reject) => {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch("logout")
          }
          throw err;
        });
      });
    }
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#a_exit{
  color: #007bff !important;
  position: absolute;
  margin-left: 40%;
}

h1, h2, h3, h4, h5, h6{
  line-height: 3.2 !important;
}

.form-control, .custom-select{
  width: 40% !important;
  margin: 0 auto;
}

.input__wrapper {
  width: 100%;
  position: relative;
  margin: 15px 0;
  text-align: center;
}
 
.input__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}
 
.input__file-icon-wrapper {
  height: 60px;
  width: 60px;
  margin-right: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-right: 1px solid #fff;
}
 
.input__file-button-text {
  line-height: 1;
  margin-top: 1px;
}
 
.input__file-button {
  width: 100%;
  max-width: 250px;
  height: 35px;
  background: #007bff;
  color: #fff;
  font-size: 1.125rem;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  border-radius: 3px;
  cursor: pointer;
  margin: 0 auto;
}

.big-table {
	overflow: auto;
	position: relative;
}
.big-table table {
	display: inline-block;
	vertical-align: top;
	max-width: 100%;
	overflow-x: auto;
	white-space: nowrap;
	-webkit-overflow-scrolling: touch;
	height: 600px;
}
thead {
  position: sticky;
  top: 0px;
}
</style>
