<template>
  
    <b-form class="login" @submit.prevent="login">
       <h4>авторизуйтесь</h4>
       <p><b-form-input required v-model="email" type="email" placeholder="Введите логин"></b-form-input></p>
       <p><b-form-input required v-model="password" type="password" placeholder="Введите пароль"></b-form-input></p>
       <p><b-button type="submit">авторизоваться</b-button></p>
    </b-form>    
  
</template>

<script>
export default {
  data(){
      return {
        email : "",
        password : ""
      }
  },
  methods: {
      login: function () {
       this.$store.dispatch('login', { 'q' : 'authorization', 'user' : { 'email' : this.email, 'password' : this.password }})
       .then(() => this.$router.push('/'))
       .catch(err => console.log(err))
      }
  }
              
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
