    
  <template>
  
  <div> 
   <a v-b-toggle.sidebar-no-header class="nav_custom">управление</a>   
    <b-sidebar id="sidebar-no-header" aria-labelledby="sidebar-no-header-title" no-header shadow :no-close-on-route-change=true>
      <template #default="{ hide }">
        <div class="p-3">
          <nav class="mb-3" id="left_sidebar">
            <b-nav vertical>
              <b-nav-item>
                <router-link to="/users" v-b-toggle.collapse-0>Пользователи</router-link>                 
              </b-nav-item>
              <b-nav-item>
                <router-link to="/" v-b-toggle.collapse-1>Реклама</router-link>                 
              </b-nav-item> 
              <b-nav-item>
                <router-link to="/events" v-b-toggle.collapse-2>События</router-link>                 
              </b-nav-item>
			  <b-nav-item>
                <router-link to="/rules" v-b-toggle.collapse-3>Правила</router-link>                 
              </b-nav-item>
			  <b-nav-item>
                <router-link to="/oferta" v-b-toggle.collapse-3>Оферта</router-link>                 
              </b-nav-item>
              <b-nav-item>
                 <router-link to="/stores" v-b-toggle.collapse-4>Файловые хранилища</router-link>                 
              </b-nav-item>
              <b-nav-item>
                 <router-link to="/files" v-b-toggle.collapse-5>Работа с файлами</router-link>                 
              </b-nav-item>
              <b-nav-item>
                 <router-link to="/settings" v-b-toggle.collapse-6>Настройки игры</router-link>                 
              </b-nav-item>			  
            </b-nav>
          </nav>
          <b-button variant="primary" block @click="hide">Закрыть меню</b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
  </template> 
<script>
import axios from 'axios'
import BootstrapVue from 'bootstrap-vue'
   
export default {
   data(){
      return {
        results: [],  
        role: '',
        email: '', 
        token: '',
      }
  },
 
  methods: {
  
  },
  
  created() {
       
          
     if(localStorage.getItem('role') != null){
        this.role = localStorage.getItem('role')
     }
     
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
     }     
     
     
  },
}
  
  
  </script>
  
  <style>
  .nav_custom{
     position: absolute;
     margin-left: -45%;
  }
  
  #left_sidebar{
    text-align: left;
  }
  
  
  </style>    
   