<template>
  <div>
    <h4>настройки</h4>
   <div size="sm">
      <label>управление отображением игры</label><br/>
      <input v-model="val" type="number" style="margin-bottom:10px;" />       
   </div>
   <b-button variant="outline-primary" v-on:click="changeValueSettings">Отправить</b-button>
   <br/><br/>
   
   <div size="sm">
      <label>управление отображением ответственной игры</label><br/>
      <input v-model="val_rg" type="number" style="margin-bottom:10px;" />       
   </div>
   <b-button variant="outline-primary" v-on:click="changeValueRGSettings">Отправить</b-button>
   <br/><br/>
   
   <div size="sm">
      <label>управление курсом обмена ton/coins</label><br/>
      <input v-model="exchange" type="number" style="margin-bottom:10px;" />       
   </div>
   <b-button variant="outline-primary" v-on:click="changeValueExchange">Отправить</b-button>
   <br/><br/>
   
   <div size="sm">
      <label>управление максимальной суммой ton к обмену</label><br/>
      <input v-model="max_amount_exchange" type="number" style="margin-bottom:10px;" />       
   </div>
   <b-button variant="outline-primary" v-on:click="changeValueMaxAmountExchange">Отправить</b-button>
   <br/><br/>
   
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'

export default {
  
  data(){
      return {
         val: null,
		 val_rg: null,
		 exchange: null,
		 max_amount_exchange: null,
		 
		 
         //доступы
         role: '',
         email: '', 
         token: '',		 
      }
  },
 
  methods: {
     changeValueSettings() {   
        let data = encodeURIComponent(JSON.stringify({'q' : 'set_settings', 'resp' : {'status_game' : this.val}, 'user' : {'email' : this.email, 'token' : this.token}})) 
         axios.post(this.$hostname, "data="+data)
         .then(response => {           
            alert(response.data)           
         })
         .catch(err => {
            alert('ошибка записи данных')
         })
     },
	 
	 changeValueRGSettings() {   
        let data = encodeURIComponent(JSON.stringify({'q' : 'set_rg_settings', 'resp' : {'status_rg_game' : this.val_rg}, 'user' : {'email' : this.email, 'token' : this.token}})) 
         axios.post(this.$hostname, "data="+data)
         .then(response => {           
            alert(response.data)           
         })
         .catch(err => {
            alert('ошибка записи данных')
         })
     },
	 
	 changeValueExchange() {   
        let data = encodeURIComponent(JSON.stringify({'q' : 'set_exchange', 'resp' : {'exchange' : this.exchange}, 'user' : {'email' : this.email, 'token' : this.token}})) 
         axios.post(this.$hostname, "data="+data)
         .then(response => {           
            alert(response.data)           
         })
         .catch(err => {
            alert('ошибка записи данных')
         })
     },
	 
	 changeValueMaxAmountExchange() {   
        let data = encodeURIComponent(JSON.stringify({'q' : 'set_max_amount_exchange', 'resp' : {'max_amount_exchange' : this.max_amount_exchange}, 'user' : {'email' : this.email, 'token' : this.token}})) 
         axios.post(this.$hostname, "data="+data)
         .then(response => {           
            alert(response.data)           
         })
         .catch(err => {
            alert('ошибка записи данных')
         })
     },
  },
  
  created() {
     /* доступы */
     if(localStorage.getItem('role') != null){
        this.role = localStorage.getItem('role')
     }
     
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
     }
     
     /* end */ 

     const data = JSON.stringify({'q' : 'get_settings', 'user' : {'email' : this.email, 'token' : this.token}})  
     axios.post(this.$hostname, "data="+data)
        .then(response => {
            this.val = response.data.status_game 
		    this.val_rg = response.data.status_rg_game
			this.exchange = response.data.exchange
			this.max_amount_exchange = response.data.max_amount_exchange
			
        })
        .catch(err => {
           console.log(err)
        })	 
        
  }
}  

</script>

<style>

</style>