<template>
  <div>
    <h4>файловые хранилища</h4>
    <div class="col-md-6" style="margin:0 auto;text-align:left;">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Создать" active>
            <b-card-text>
               <b-form class="login" @submit.prevent="createStore">
                 <b-form-input type="text" v-model="name" placeholder="Название хранилища (только латиница в любом регистре CamelCase)" class="form control" pattern="[A-Za-z_]{4,15}" required style="display:inline-block;" /></b-form-input>
                 <b-dropdown
                   split       
                   text="Выберите проект"
                   class="m-2"        
                 >
                 <b-dropdown-item href="#" v-for="project in projects" v-on:click="clickList(project.name)">{{project.name}}</b-dropdown-item> 
                 </b-dropdown>   
                 <b-dropdown
                   split       
                   text="Выберите тип хранилища"
                   class="m-2"        
                 >
                 <b-dropdown-item href="#" v-for="type in types" v-on:click="clickType(type.name)">{{type.name}}</b-dropdown-item> 
                 </b-dropdown>
                 <p v-show = "projectName != null || selected_type != null">
                    <span v-show = "projectName != null"> проект - {{projectName}} </span>
                    <span v-show = "selected_type != null">| тип хранилища - {{selected_type}} </span>
                 </p>
                 <div v-if="selected_type == 'локальное'">
                    <b-form-input type="text" v-model="storage_local" placeholder="введите имя хранилища"></b-form-input>
                 </div>
                 <div v-else-if="selected_type == 'ftp' || selected_type == 'облачное'">
                    <b-form-input v-if="selected_type == 'ftp'" type="text" v-model="ftp_host" placeholder="введите имя хоста"></b-form-input>
                    <b-form-input v-if="selected_type == 'облачное'" type="text" v-model="cloud_storage" placeholder="введите адрес хоста с номером аккаунта"></b-form-input>
                    <!--<b-form-input v-if="selected_type == 'облачное'" type="text" v-model="storage_token" placeholder="введите token"></b-form-input>-->
                    <b-form-input type="text" v-model="storage_login" placeholder="введите логин"></b-form-input>
                    <b-form-input type="text" v-model="storage_pass" placeholder="введите пароль"></b-form-input>
                 </div>                 
                   
                 <p><b-button type="submit" variant="primary">отправить</b-button></p>
                 </b-form> 
                 <p>{{ results }}</p>           
            
            </b-card-text>
          </b-tab>
          <b-tab title="Удалить">
            <b-card-text>
               <table class="table b-table table-striped table-hover table-bordered b-table-fixed table-light" role="table" aria-busy="false" aria-colcount="4" style="width:100% !important">
                  <thead class="thead-dark" role="rowgroup">
                     <tr class="" role="row">
                        <th role="columnheader" scope="col" aria-colindex="1" class=""><div>название хранилища</div></th>
                        <th role="columnheader" scope="col" aria-colindex="2" class=""><div>тип</div></th>
                        <th role="columnheader" scope="col" aria-colindex="3" class=""><div>хост</div></th>
                        <th role="columnheader" scope="col" aria-colindex="2" class=""><div>проект</div></th>  
                        <th role="columnheader" scope="col" aria-colindex="4" aria-label="Label" class=""><div></div></th>        
                     </tr>
                  </thead>
                  <tbody>          
                     <tr class='clickable-row' role="row" v-for="store in stores" :key="store.name" >
                        <td class="" role="cell">  {{ store.name_storage }}</td>
                        <td class="" role="cell">{{ store.type_storage }}</td>
                        <td class="" role="cell">{{ store.host }}</td>
                        <td class="" role="cell">{{ store.project }}</td>          
                        <td class="" role="cell" v-on:click="clickDeleteStore(store)"><b-button variant="danger">удалить</b-button></td>
                     </tr>
                  </tbody>
               </table>
            
            </b-card-text>
          </b-tab>
          <b-tab title="Доступ/права">
            <b-card-text></b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'


export default {
   
  data(){
      return {
        name : "",
        description : "",
        results: "",        
        selected: null,          
        projects: [],
        stores: [],
        projectName: null,
        types: [
          { name : 'локальное'},
          { name : 'ftp' },
          { name : 'облачное' },
        ],
        store_local_patch: null,
        type_property: null,
        selected_type: null, //выбранный тип хранилища
        storage_local: null, //путь к локальному хранилищу
        ftp_host: null, //имя ftp хоста
        cloud_storage: null, //адрес облачного хранилища URL
        storage_login: null,
        storage_pass: null,
        //storage_token: null,
        //доступы
        role: '',
        email: '', 
        token: '',
        //end
      }
      
  },
  methods: {
      createStore: function () {
        let type;
        switch(this.selected_type){
           case 'облачное':
             type = 'cload_storage';
             break;
           case 'ftp':
             type = 'ftp';
             break;
           case 'локальное':
             type = 'local';             
             break;           
        }
        
        
        const data = JSON.stringify({
           'q' : 'add_store',
           'store' : {
                  'project_name' : this.projectName,
                  'name_storage' : this.name,
                  'selected_type' : type,
                  'storage_local' : this.storage_local,
                  'ftp_host' : this.ftp_host,
                  'cloud_storage' : this.cloud_storage,
                  'storage_login' : this.storage_login,
                  'storage_pass' : this.storage_pass,
                  //'storage_token' : this.storage_token,
               },
           'user' : {
                  'email' : this.email,
                  'token' : this.token
               }
        }) 
            
        axios.post(this.$hostname+'store', "data="+data)
        .then(response => {
           alert(response.data.result)
           location.reload()
        })
        .catch(err => {
           console.log(err)
        }) 
      
      },
      
      
      
      //получаем выбранный из списка проект
      clickList: function (project) { 
         this.projectName = project
      },
      
      //получаем выбранный из списка тип хранилища
      clickType: function (type) {
         this.selected_type = type;
      },
      
      clickDeleteStore: function (store) {
         let deleted_store = confirm('Вы хотите удалить хранилище ' + store.name_storage + ' ?' )
         if(deleted_store){
            const data = JSON.stringify({'q' : 'delete_store', 'store' : {'name_storage' : store.name_storage, 'type_storage' : store.type_storage, 'host' : store.host, 'project' : store.project }, 'user' : {'email' : this.email, 'token' : this.token}})  
            axios.post(this.$hostname+'store', "data="+data)
            .then(response => {
               alert(response.data.result)
               location.reload()
            })
            .catch(err => {
               alert('Хранилище ' + store.name_storage + ' не удален. причина ' + err )
            })
            
         }
      }
  },
  
  created() {
     /* доступы */
     if(localStorage.getItem('role') != null){
        this.role = localStorage.getItem('role')
     }
     
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
     }
     
     /* end */
     
     const data = JSON.stringify({'q' : 'all_projects'})  
     axios.post(this.$hostname+'collection', "data="+data)
        .then(response => {
           
           this.projects = response.data.result
        })
        .catch(err => {
           console.log(err)
        })
        
     const req = JSON.stringify({'q' : 'all_stores'})  
     axios.post(this.$hostname+'store', "data="+req)
        .then(resp => {
           
           this.stores = resp.data.result
        })
        .catch(err => {
           console.log(err)
        })   
     
        
  }
              
}


</script>