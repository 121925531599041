import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use( Vuex )

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user : {},
    tableName: '',
    RowData : {},
  },
  
  mutations: {
  	   auth_request(state){
         state.status = 'loading'
      },
      auth_success(state, token, user){
         state.status = 'success'
         state.token = token
         state.user = user
      },
      auth_error(state){
         state.status = 'error'
      },
      logout(state){
         state.status = ''
         state.token = ''
      }, 
      updateTableName(state, table){
         state.tableName = table
      },
      changeRowData(state, str){
      	state.RowData = str
      },    
  },
  
  actions: {
  	  login({commit}, user){
       return new Promise((resolve, reject) => {
          commit('auth_request')
          const data = JSON.stringify(user)
          axios.post('https://myhap.ru/cards-admin', 'data='+data)
          .then(resp => {
             const token = resp.data.token
             const user = resp.data.user
             const role = resp.data.role
             const email = resp.data.email
             localStorage.setItem('token', token)
             localStorage.setItem('role', role)
             localStorage.setItem('login', user)
             localStorage.setItem('email', email)
             axios.defaults.headers.common['Authorization'] = token
             commit('auth_success', token, user)
             resolve(resp)
          })
          .catch(err => {
             commit('auth_error')
             localStorage.removeItem('token')
             reject(err)
          })
       })
    },
    logout({commit}){
       return new Promise((resolve, reject) => {
          commit('logout')
          localStorage.removeItem('token')
          localStorage.removeItem('role')
          localStorage.removeItem('login')
          localStorage.removeItem('email')
          delete axios.defaults.headers.common['Authorization']
          resolve()
       })
    },   
  	
  },
  getters : {
  	    isLoggedIn: state => !!state.token,
       authStatus: state => state.status,  
       nameTable: state => state.tableName,	
       rowData: state => state.RowData,
  }
})