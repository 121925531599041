import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Users from './views/Users.vue'
import Login from './components/Login.vue'
import Secure from './components/Secure.vue'
import Stores from './views/Stores.vue'
import Files from './views/Files.vue'
import Reclame from './views/Reclame.vue'
import Events from './views/Events.vue'
import Rules from './views/Rules.vue'
import Settings from './views/Settings.vue'
import Oferta from './views/Oferta.vue'

Vue.use(Router)

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'reclame',
      component: Reclame,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/secure',
      name: 'secure',
      component: Secure,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: Users,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/stores',
      name: 'stores',
      component: Stores,
      meta: { 
        requiresAuth: true
      }
    },
    {
      path: '/files',
      name: 'files',
      component: Files,
      meta: { 
        requiresAuth: true
      }
    },      
    {
      path: '/events',
      name: 'events',
      component: Events,
      meta: { 
        requiresAuth: true        
      }
    },
	{
      path: '/rules',
      name: 'rules',
      component: Rules,
      meta: { 
        requiresAuth: true        
      }
    },
	{
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { 
        requiresAuth: true        
      }
    },
	{
      path: '/oferta',
      name: 'oferta',
      component: Oferta,
      meta: { 
        requiresAuth: true        
      }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
  
  
})
export default router