<template>
  <div>
    <h4>пользователи</h4>
    <b-table striped hover :items="results" :fields="fields" :bordered="bordered" :dark="dark" :fixed="fixed" :table-variant="tableVariant" :head-variant="headVariant"></b-table>
    
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data(){
      return {
        fields: [{key : 'name', label : 'имя'}, {key : 'login', label : 'логин'}, {key : 'role', label : 'роль'}],
        results: [],
        bordered: true,
        dark: false,
        fixed: true,
        tableVariant: 'light',
        headVariant: 'dark',
      }
  },
  methods: {
      
  },
  created() {
     const data = JSON.stringify({'q' : 'all_users'})  
     axios.post(this.$hostname+'users', "data="+data)
        .then(response => {
           this.results = response.data.result
        })
        .catch(err => {
           console.log(err)
        })
        
  }
              
}
</script>

<style>

table {
  width: 50% !important;
  margin: 0 auto;
}

</style>

