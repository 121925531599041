<template>
  <div>
    <h4>работа с файлами</h4>
    <div class="col-md-6" style="margin:0 auto;text-align:left;">
      <b-card no-body>
        <b-tabs card>
          <b-tab title="Просмотр" active>
            <b-card-text>
               <b-dropdown
                   split       
                   text="Выберите хранилище"
                   class="upload_files_group"        
               >
               <b-dropdown-item href="#" v-for="store in stores" v-on:click="clickStoreStartView(store.name_storage)">{{store.name_storage}}</b-dropdown-item> 
               </b-dropdown> 
               <div v-show = "selected_store != null">
                  <div v-for="file in files" style="margin: 2px 10px;">
                    <div style="display:inline-block;width:60%"> 
                     <img v-if="file.format == 'webp'" src="../assets/icons_format/webp.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'csv'" src="../assets/icons_format/csv.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'avif'" src="../assets/icons_format/avif.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'doc'" src="../assets/icons_format/doc.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'docx'" src="../assets/icons_format/docx.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'jpeg'" src="../assets/icons_format/jpeg.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'jpg'" src="../assets/icons_format/jpg.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'pdf'" src="../assets/icons_format/pdf.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'png'" src="../assets/icons_format/png.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'txt'" src="../assets/icons_format/txt.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'xls'" src="../assets/icons_format/xls.png" style="width:30px;height:30px" />
                     <img v-else-if="file.format == 'xlsx'" src="../assets/icons_format/xlsx.png" style="width:30px;height:30px" />
                     {{file.name}}
                    </div> 
                    <div style="display:inline-block;width:40%">
                       <a href="#" title="редактировать"><img src="../assets/edit.png" style="width:25px;height:25px" /></a> <a href="#" title="удалить" v-on:click="deleteFile(file.name)"><img src="../assets/delete.png" style="width:25px;height:25px" /></a>
                    </div>                     
                  </div>                                
               </div>            
            </b-card-text>
          </b-tab>
          <b-tab title="Добавить">
            <b-card-text>
               <!-- загрузка файлов -->
               <div class="input__wrapper" style="display:inline-block;width:31%;">
                  <input name="file" type="file" id="input__file" class="input input__file" ref="file" accept=".csv, .xls, .xlsx, .jpg, .jpeg, .png, .webp, .avif, .pdf, .doc, .docx, .txt" v-on:change="handleFileUpload()">
                  <label for="input__file" class="input__file-button" style="margin:0">
                  <span class="input__file-icon-wrapper"><img class="input__file-icon" src="../assets/add.svg" alt="Выбрать файл" width="25"></span>
                  <span class="input__file-button-text">загрузить файл</span>
                  </label>                        
               </div>      
               <!-- end -->
               <b-dropdown
                   split       
                   text="Выберите хранилище"
                   class="upload_files_group"        
               >
               <b-dropdown-item href="#" v-for="store in stores" v-on:click="clickStore(store.name_storage)">{{store.name_storage}}</b-dropdown-item> 
               </b-dropdown> 
               <div class="upload_files_group">
                  <span v-show = "selected_store != null">Выбрано хранилище - {{selected_store}}</span>
               </div>           
            </b-card-text>
          </b-tab>
          <!--<b-tab title="Редактировать">
            <b-card-text>
            
            </b-card-text>
          </b-tab>
          <b-tab title="Конвертировать">
            <b-card-text>
            
            </b-card-text>
          </b-tab>-->
        </b-tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import axios from 'axios'


export default {
   
  data(){
      return {
        name : "",
        description : "",
        results: "",        
        selected: null,          
        stores: [],
        file_local_patch: null,
        type_property: null,
        selected_store: null, //выбранный тип хранилища
        storage_local: null, //путь к локальному хранилищу
        ftp_host: null, //имя ftp хоста
        cloud_storage: null, //адрес облачного хранилища URL
        storage_login: null,
        storage_pass: null,
        //storage_token: null,
        //доступы
        role: '',
        email: '', 
        token: '',
        //end
        files: [],
      }
      
  },
  methods: {
      //загрузка файлов на сервер
      handleFileUpload(){
          if(this.selected_store != null){
             //отправляем файл 
             this.file = this.$refs.file.files[0];
             this.filename = this.file.name;
             let formData = new FormData();
             formData.append('file', this.file);
             axios.post( this.$hostname+'file/upload',
                formData,
                {
                   headers: {
                       'Content-Type': 'multipart/form-data'
                   }
                
                }
             ).then(response => {
                //alert(response.data.result)
             })
             .catch(response => {
                alert(response.data.result)
             });
          
             //отправляем название хранилища  в которое добавляем файл
             let data = JSON.stringify({'q' : 'add_file', 'file' : {'name_storage' : this.selected_store, 'file_name' : this.filename}, 'user' : {'email' : this.email, 'token' : this.token}})  
             axios.post(this.$hostname+'file', "data="+data)
             .then(response => {           
                 alert(response.data.result)              
             })
             .catch(err => {
                 console.log(err)
             })
          }else{
             alert("выберите хранилище для загружаемого файла")
          }
      },
      
            
      //получаем выбранный из списка тип хранилища
      clickStore: function (store) {
         this.selected_store = store
      },
      
      //выбираем хранилище на стартовом табе
      clickStoreStartView: function (store) {
         this.selected_store = store
         
         const req = JSON.stringify({'q' : 'get_files', 'file' : {'name_storage' : this.selected_store}, 'user' : {'email' : this.email, 'token' : this.token}})  
         axios.post(this.$hostname+'file', "data="+req)
            .then(resp => {           
               this.files = resp.data.result
            })
            .catch(err => {
               console.log(err)
            })   
         
      },
      
      //удаляем файл
      deleteFile: function (file_name) {
                 
         const req = JSON.stringify({'q' : 'delete_file', 'file' : {'name_storage' : this.selected_store, 'file_name' : file_name}, 'user' : {'email' : this.email, 'token' : this.token}})  
         axios.post(this.$hostname+'file', "data="+req)
            .then(resp => {           
               alert(resp.data.result)
               if(resp.data.result == 'файл успешно удалён'){
                  delete this.files.file_name
               }
            })
            .catch(err => {
               console.log(err)
            })   
         
      },
      
      
  },
  
  created() {
     /* доступы */
     if(localStorage.getItem('role') != null){
        this.role = localStorage.getItem('role')
     }
     
     if(localStorage.getItem('token') != null){
        this.token = localStorage.getItem('token')
     }
     
     if(localStorage.getItem('email') != null){
        this.email = localStorage.getItem('email')
     }
     
     /* end */    
             
     const req = JSON.stringify({'q' : 'all_stores'})  
     axios.post(this.$hostname+'store', "data="+req)
        .then(resp => {
           
           this.stores = resp.data.result
        })
        .catch(err => {
           console.log(err)
        })   
     
        
  }
              
}


</script>
<style>
.upload_files_group{
   width:31%;
   margin-top:-15px;
   margin-left: 10px;
   display: inline-block;
}

</style>
